import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { navMenuState } from '../recoil/common';
import { useLocation } from 'react-router-dom';

export default function useScrollColor<T extends HTMLElement>(
  ref: React.RefObject<T>,
  changeBreakpoint: number,
  color?: string,
) {
  const { pathname } = useLocation();
  const setNavColor = useSetRecoilState(navMenuState);
  const metaTag = useRef(document.querySelector('meta[name="theme-color"]'));

  const handleScrollEvent = () => {
    if (ref && ref.current) {
      // const scrollTop = window.scrollY;
      const startRefTop = ref.current.offsetTop - window.scrollY;

      console.log({ startRefTop, changeBreakpoint });

      if (startRefTop <= changeBreakpoint && metaTag && metaTag.current) {
        metaTag.current.setAttribute('content', color + '');
        setNavColor(color + '');
      }
    }
  };

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    handleScrollEvent();
  }, [pathname]);
}
