import {
  LBTProgressSpinner,
  LBTLabel,
  COLORS,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { ReactComponent as HoorayImg } from '../../assets/images/hooray.svg';

export default function QuestionaryLoader() {
  return (
    <Section backgroundColor={COLORS.getInstance().PRIMARY_MAIN}>
      <LBTSpacer spacing={2} isFixed />
      <HoorayImg />
      <LBTLabel variant="sourceSubtitle2" component="p">
        A breve potrai vedere i primi risultati
      </LBTLabel>
      <LBTSpacer spacing={1} />
      <LBTLabel variant="delaDisplay" component="h1">
        E il grosso è fatto!
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Le tue risposte ci hanno permesso di eliminare le agevolazioni a cui
        sicuramente non hai diritto
      </LBTLabel>
      <LBTSpacer spacing={6} />
      <LBTProgressSpinner color="neutral" />
      <LBTSpacer spacing={8} isFixed />
    </Section>
  );
}
