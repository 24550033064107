import {} from '@laborability/commons';
import {
  COLORS,
  IconCrossComponent,
  IconsSize,
  IconsStyle,
  LBTButton,
  LBTButtonIcon,
  LBTLabel,
} from '@laborability/components';
import { Grid, Modal, Paper, Stack } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  isDesktop: boolean;
}

export default function ModalRicerca({ open, onClose, isDesktop }: Props) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        style={{
          width: isDesktop ? '489px' : '90%',
          borderRadius: '16px',
        }}
      >
        <Grid container spacing="24px" style={{ padding: '16px' }}>
          <Grid item mobile={12} desktop={12}>
            <Stack direction="row" alignItems="start">
              <LBTLabel
                style={{ marginRight: '1px' }}
                textAlign="left"
                variant={'sourceSubtitle2'}
              >
                Al momento in questa categoria non ci sono agevolazioni per te,
                ma puoi continuare a rispondere
              </LBTLabel>
              <LBTButtonIcon size="small" onClick={() => {}}>
                <IconCrossComponent
                  style={IconsStyle.FILLED}
                  size={IconsSize.MEDIUM}
                  color={COLORS.getInstance().BW_GREYS_JET_BLACK}
                ></IconCrossComponent>
              </LBTButtonIcon>
            </Stack>
          </Grid>
          <Grid item mobile={12} desktop={12}>
            <LBTLabel textAlign="left" variant={'bodyText2'}>
              Oggi non hai i requisiti, ma non significa che la situazione
              rimarrà così per sempre. Durante l'anno vengono pubblicati nuovi
              bonus e le condizioni di accesso possono cambiare. Continua a
              rispondere per mantenere il profilo aggiornato.
            </LBTLabel>
          </Grid>
          <Grid item mobile={12} desktop={12}>
            <Grid container justifyContent="right">
              <Grid item mobile={7} desktop={7}>
                <Stack direction="row" spacing="16px">
                  <LBTButton
                    variant="invisible"
                    fullWidth
                    size="medium"
                    onClick={onClose}
                  >
                    Annulla
                  </LBTButton>
                  <LBTButton
                    variant="contained"
                    fullWidth
                    size="medium"
                    onClick={onClose}
                  >
                    Continua
                  </LBTButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
