import {
  COLORS,
  LBTCard,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  Misura,
  getMisureByTagCallback,
  getTagByIdCallback,
  measuresState,
  tagState,
} from '@laborability/commons';
import InfiniteDiscover from '../../components/InfiniteDiscover';
import { APP_ROUTES } from '../../Routes';
import useTrackPage from '../../hooks/useTrackPage';
import { slugify } from '../../utils/slugify';
import ImageBox from '../../components/ImageBox';

export default function MisuraPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const tag = useRecoilValue(tagState);
  const misure = useRecoilValue(measuresState);

  const fetchMisure = useRecoilCallback(getMisureByTagCallback, []);
  const getTabById = useRecoilCallback(getTagByIdCallback, []);

  const [tagName, setTagName] = useState<string>();

  const getMisure = async (
    skip: number,
    limit: number,
    progressive: boolean,
  ) => {
    const res = await fetchMisure({
      tag_id: Number(id),
      skip,
      limit,
      published: true,
      progressive,
    });
    if (!res || !res.data) return [];
    return res.data;
  };

  useEffect(() => {
    getTabById({ id: Number(id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tag.id?.toString() === window.location.href.split('/').pop())
      setTagName(tag.name);
  }, [tag]);

  useTrackPage(
    !!tagName ? `Tag ${tagName}` : '',
    !!tagName ? `/app/tag/${slugify(tagName)}` : '',
  );

  if (!tag) return null;

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        {tag?.name ?? ''}
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        {tag?.description ?? ''}
      </LBTLabel>
      <LBTSpacer spacing={2} />
      {tag?.image && <ImageBox src={tag.image} style={{ maxWidth: '200px' }} />}
      <LBTSpacer spacing={2} />
      <InfiniteDiscover
        items={misure}
        getMoreItems={getMisure}
        hasTotalItemsLabel
        renderItem={(item: Misura) => (
          <Grid item mobile={12} desktop={6} display="flex">
            <LBTCard
              onClick={() =>
                navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)
              }
              backgroundColor={COLORS.getInstance().STATUS_INFO_BLUE_MAIN}
              title={item.name}
              description={item.description}
              tag={item.categories
                ?.find(category =>
                  item.categories!.length === 2
                    ? !!category.parent_id
                    : category.parent_id === null,
                )
                ?.name?.toLowerCase()}
              news={item.news ?? false}
              expired={item.expired ?? false}
              expiringSoon={item.expiring_soon ?? false}
              upcoming={item.upcoming ?? false}
            />
          </Grid>
        )}
      />
    </Section>
  );
}
