import {
  COLORS,
  ImageFamily,
  ImageKeyDoor,
  ImageManAndMagnifyingGlass,
  ImagePuzzle,
  ImageTurtleAndBunny,
  LBTButton,
  LBTButtonIllustrative,
  LBTLabel,
  LBTPagination,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { useEffect, useState } from 'react';
import {
  categoriesState,
  getCategoriesHomePageCallback,
  getUserProfileCallback,
  initFlowCallback,
  questionFlowsState,
  useBreakpoint,
} from '@laborability/commons';
import { Grid } from '@mui/material';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import { IconSalute } from '../../components/icons/IconSalute';
import { IconCultura } from '../../components/icons/IconCultura';
import { IconIstruzione } from '../../components/icons/IconIstruzione';
import { IconTrasporti } from '../../components/icons/IconTrasporti';
import { IconGenitorialita } from '../../components/icons/IconGenitorialita';
import { IconCasa } from '../../components/icons/IconCasa';

const ITEMS = [
  {
    image: <ImageManAndMagnifyingGlass />,
    title: '1. Profilo personale',
    subtitle: 'domande semplici per iniziare e rompere il ghiaccio',
    color: COLORS.getInstance().TANGERINE_MAIN,
  },
  {
    image: <ImageFamily />,
    title: '2. I tuoi cari',
    subtitle:
      'perché ci teniamo a mostrarti anche i bonus per chi ti sta a cuore',
    color: COLORS.getInstance().BERRY_POPSICLE_MAIN,
  },
  {
    image: <ImageKeyDoor />,
    title: '3. Super domande',
    subtitle: 'domande difficili ma cruciali per valutare tantissimi bonus',
    color: COLORS.getInstance().MINT_TONIC_MAIN,
  },
  {
    image: <ImagePuzzle />,
    title: '4. Le categorie',
    subtitle:
      'domande specifiche per sbloccare i bonus che ti interessano, divisi per area tematica',
    color: COLORS.getInstance().LEMON_CAKE_MAIN,
  },
];

interface Props {
  closeIntro: () => void;
}

export default function Intro({ closeIntro }: Props) {
  const { isDesktop } = useBreakpoint();
  const [currentPage, setCurrentPage] = useState(0);

  const renderItem = (item: (typeof ITEMS)[number]) => {
    return (
      <Grid item key={item.title} mobile={12} desktop={6} display="flex">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            border: `1px solid ${item.color}`,
            borderRadius: '12px',
            backgroundColor: `${item.color}26`,
            alignItems: 'center',
            width: '100%',
          }}
        >
          {item.image}
          <LBTLabel variant="bodyText" style={{ fontWeight: 700 }}>
            {item.title}
          </LBTLabel>
          <LBTLabel variant="bodyText">{item.subtitle}</LBTLabel>
        </div>
      </Grid>
    );
  };

  if (currentPage === 0)
    return (
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Semplifichiamo la complessità
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Là fuori c’è una giungla di bonus, incentivi, agevolazioni, sgravi
          fiscali. Capirci qualcosa è un’impresa, ma la fatica la facciamo noi
          per te
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <ImageTurtleAndBunny />
        <LBTSpacer spacing={2} />
        <LBTLabel variant="bodyText1">
          Rispondi alle domande del Questionario e sblocca i tuoi bonus: solo
          quelli che ti spettano, senza rumore di fondo
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <LBTButton
          onClick={() => {
            setCurrentPage(1);
          }}
          variant="contained"
          sx={{ width: '100%', maxWidth: '358px' }}
        >
          Avanti
        </LBTButton>
      </Section>
    );

  if (currentPage === ITEMS.length + 1)
    return <Onboarding closeIntro={closeIntro} />;

  return (
    <Section>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Sblocca i tuoi bonus con il Questionario
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Abbiamo preparato una serie di domande. Le tue risposte ci permettono di
        valutare centinaia di bonus e a selezionare solo quelli giusti per te
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <div style={{ maxWidth: '504px', width: '100%' }}>
        {ITEMS && renderItem(ITEMS[currentPage - 1])}
      </div>
      <LBTSpacer spacing={2} />
      <LBTPagination
        numberOfItems={ITEMS.length}
        onPageChange={tmpPage => setCurrentPage(tmpPage)}
        currentPage={currentPage}
      />

      <LBTSpacer spacing={4} />
      {currentPage === 4 ? (
        <LBTButton
          onClick={() => setCurrentPage(ITEMS.length + 1)}
          variant="contained"
          sx={{ width: '100%', maxWidth: '358px' }}
        >
          Avanti
        </LBTButton>
      ) : (
        <LBTButton
          onClick={() => setCurrentPage(ITEMS.length + 1)}
          variant="invisible"
          sx={{ width: '100%', maxWidth: '358px' }}
        >
          Salta l'introduzione
        </LBTButton>
      )}
      <LBTSpacer spacing={6} />
    </Section>
  );
}

function Onboarding({ closeIntro }: Props) {
  const { isDesktop } = useBreakpoint();
  const categories = useRecoilValue(categoriesState);
  const getAllCategories = useRecoilCallback(getCategoriesHomePageCallback, []);

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Le tue agevolazioni
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Sblocca le agevolazioni che ti spettano rispondendo alle domande del
        Questionario
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="bodyText2" component="p">
        Alla fine tornerai qui e vedrai incentivi, sgravi e bonus divisi per
        categoria. Per sbloccarli tutti, entra nelle categorie che ti
        interessano e rispondi a qualche altra domanda
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTButton
        backgroundColor={COLORS.getInstance().PRIMARY_MAIN}
        onClick={closeIntro}
        size="large"
        sx={{ width: '100%', maxWidth: '358px' }}
        variant="contained"
      >
        Inizia il Questionario
      </LBTButton>
      <LBTSpacer spacing={4} />
      <Grid
        container
        spacing={isDesktop ? '24px' : '16px'}
        style={{ maxWidth: isDesktop ? '1032px' : '390px', width: '100%' }}
      >
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_salute_unlocked`}
            onClick={() => {}}
            icon={<IconSalute />}
            label="Salute"
            locked={true}
            iconBgColor={COLORS.getInstance().TANGERINE_MAIN}
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_cultura_unlocked`}
            onClick={() => {}}
            icon={<IconCultura />}
            label="Cultura"
            locked={true}
            iconBgColor={COLORS.getInstance().TANGERINE_MAIN}
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_istruzione_unlocked`}
            onClick={() => {}}
            icon={<IconIstruzione />}
            label="Istruzione"
            locked={true}
            iconBgColor={COLORS.getInstance().TANGERINE_MAIN}
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_trasporti_unlocked`}
            onClick={() => {}}
            icon={<IconTrasporti />}
            label="Trasporti"
            locked={true}
            iconBgColor={COLORS.getInstance().TANGERINE_MAIN}
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_genitorialita_unlocked`}
            onClick={() => {}}
            icon={<IconGenitorialita />}
            label="Genitorialità"
            locked={true}
            iconBgColor={COLORS.getInstance().TANGERINE_MAIN}
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_casa_unlocked`}
            onClick={() => {}}
            icon={<IconCasa />}
            label="Casa"
            locked={true}
            iconBgColor={COLORS.getInstance().TANGERINE_MAIN}
          />
        </Grid>
      </Grid>
      <LBTSpacer spacing={6} />
    </Section>
  );
}
