import {
  COLORS,
  LBTAccordion,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { SolaryFits } from '../../components/SolaryFits';
import { StaticArticles } from '../../components/StaticArticles';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxOne,
  AnswerBoxThree,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationFourthBoxes';
import { ARTICLES, Image } from './Fifth';
import useTrackPage from '../../hooks/useTrackPage';

const ACCORDION_ITEMS = [
  {
    question: 'Quali somme si possono cedere?',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Ci sono dei limiti',
    answer: <AnswerBoxTwo />,
  },
  {
    question: 'Qual è la procedura da seguire?',
    answer: <AnswerBoxThree />,
  },
];

const Fifth06 = () => {
  useTrackPage(
    'Cessione del quinto - Articolo 6',
    '/app/cessione-quinto/articol-6',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().LEMON_CAKE_SUPERLIGHT}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Cessione del quinto della pensione INPS, come funziona
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Il pensionato può cedere una quota della propria pensione per ripagare
          un prestito? Quali requisiti sono necessari? Ci sono dei limiti?
        </LBTLabel>
        <LBTSpacer spacing={4} />
        {ARTICLES[5].image && <Image src={ARTICLES[5].image} />}

        <LBTLabel
          variant="bodyText"
          textAlign="left"
          paddingBottom={'16px'}
          paddingTop={'48px'}
        >
          La <BoldSpan>cessione del quinto della pensione</BoldSpan> è un{' '}
          <BoldSpan>modo con cui un pensionato estingue un</BoldSpan> proprio{' '}
          <BoldSpan>debito</BoldSpan>. Ciò avviene quando il{' '}
          <BoldSpan>
            pensionato chiede un prestito a un istituto finanziario
          </BoldSpan>
          , offrendo di{' '}
          <BoldSpan>
            ripagarlo attraverso una quota fissa della pensione
          </BoldSpan>{' '}
          dovutagli dall'INPS.
        </LBTLabel>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            key={index}
            uniqueKey={`${index}`}
            details={item.answer}
            summary={item.question}
          />
        ))}
        <LBTSpacer spacing={4} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h3">
          Vai ai prossimi articoli
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <StaticArticles toExclude={[5]} />
      </Section>
    </>
  );
};

export default Fifth06;
