import {
  COLORS,
  LBTAccordion,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { SolaryFits } from '../../components/SolaryFits';
import { StaticArticles } from '../../components/StaticArticles';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxFive,
  AnswerBoxFour,
  AnswerBoxOne,
  AnswerBoxSeven,
  AnswerBoxSix,
  AnswerBoxThree,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationFirstBoxes';
import { ARTICLES, Image } from './Fifth';
import useTrackPage from '../../hooks/useTrackPage';

const ACCORDION_ITEMS = [
  {
    question: 'Quali somme si possono cedere?',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Quali requisiti deve avere il lavoratore?',
    answer: <AnswerBoxTwo />,
  },
  {
    question: 'Chi non può cedere il quinto dello stipendio?',
    answer: <AnswerBoxThree />,
  },
  {
    question:
      'Quali sono i limiti di durata della cessione del quinto dello stipendio?',
    answer: <AnswerBoxFour />,
  },
  {
    question: 'Cosa deve fare il datore di lavoro?',
    answer: <AnswerBoxFive />,
  },
  {
    question:
      'Può essere stipulata una seconda cessione del quinto dello stipendio?',
    answer: <AnswerBoxSix />,
  },
  {
    question: 'Cosa succede se lo stipendio è oggetto anche di pignoramento?',
    answer: <AnswerBoxSeven />,
  },
];

const Fifth01 = () => {
  useTrackPage(
    'Cessione del quinto - Articolo 1',
    '/app/cessione-quinto/articol-1',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().PRIMARY_SUPERLIGHT}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Cos'è la cessione del quinto dello stipendio?
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Il lavoratore può cedere una quota del proprio stipendio per ripagare
          un prestito. Quali requisiti sono necessari? Ci sono dei limiti di
          durata della cessione?
        </LBTLabel>
        <LBTSpacer spacing={4} />
        {ARTICLES[0].image && <Image src={ARTICLES[0].image} />}

        <LBTLabel
          variant="bodyText"
          textAlign="left"
          paddingBottom={'16px'}
          paddingTop={'48px'}
        >
          La cessione del quinto dello stipendio è un modo con cui un lavoratore{' '}
          <BoldSpan>estingue un proprio debito</BoldSpan>. Ciò avviene quando il
          lavoratore chiede un{' '}
          <BoldSpan>prestito a un istituto finanziario</BoldSpan>, offrendo di
          ripagarlo attraverso una{' '}
          <BoldSpan>quota fissa dello stipendio</BoldSpan> dovutogli dal datore
          di lavoro.
        </LBTLabel>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            key={index}
            uniqueKey={`${index}`}
            summary={item.question}
            details={item.answer}
          />
        ))}
        <LBTSpacer spacing={4} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h3">
          Vai ai prossimi articoli
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <StaticArticles toExclude={[0]} />
      </Section>
    </>
  );
};

export default Fifth01;
