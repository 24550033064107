import { PATH, authGetAll, authPost } from '../../http';
import { ContinueFlow, EvalAction, Id, UpdateVisibility } from '../types';

export const initFlow = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.QUESTIONARIES}/start-flow/${id}`);
};

export const continueFlow = async ({ flow_id, page_id }: ContinueFlow) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/continue-flow/${flow_id}/${page_id}`,
  );
};

export const evalAction = async (params: EvalAction) => {
  return await authPost(`api/v1/${PATH.QUESTIONARIES}/eval-action`, params);
};

export const updateVisibility = async (params: UpdateVisibility) => {
  return await authPost(
    `api/v1/${PATH.QUESTIONARIES}/update-visibility`,
    params,
  );
};
