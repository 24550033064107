import {
  currentUserState,
  getAllQuestionFlowCallback,
  getUserProfileCallback,
  questionFlowsState,
  useBreakpoint,
  userLoaderState,
} from '@laborability/commons';
import {
  IconArrowRightComponent,
  IconQuestionaryStep,
  IconWarningComponent,
  IconsStyle,
  ImageAstronaut,
  LBTButton,
  LBTChip,
  LBTLabel,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { APP_ROUTES } from '../../Routes';

export default function QuestionaryMenu() {
  const { isDesktop } = useBreakpoint();
  const user = useRecoilValue(currentUserState);
  const flows = useRecoilValue(questionFlowsState);
  const getFlows = useRecoilCallback(getAllQuestionFlowCallback, []);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const userLoader = useRecoilValue(userLoaderState);
  const navigate = useNavigate();
  const steps = user.questionary_status?.map(item => ({
    ...flows.find(flow => flow.id === item.flow_id),
    position: item.position ?? 0,
  }));
  const isQuestionaryCompleted =
    !userLoader && !steps?.find(step => step.position !== step.number_of_pages);

  useEffect(() => {
    getFlows();
    getUserMe();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Questionario
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Puoi modificare le risposte o continuare a rispondere se non hai
          ancora finito
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <Box
          component="ul"
          maxWidth="680px"
          width="100%"
          padding={0}
          margin={0}
        >
          {steps?.map(step => (
            <LBTListItem
              key={step.id}
              component="a"
              title={step.name!}
              leftItem={<IconQuestionaryStep step={step.step!} />}
              rightItem={
                <Stack flexDirection="row" alignItems="center">
                  {step.step === 3 &&
                    step.number_of_pages !== step.position && (
                      <LBTChip
                        icon={
                          <IconWarningComponent style={IconsStyle.OUTLINE} />
                        }
                        color="tangerine"
                        sx={{ minWidth: '50px', marginRight: '10px' }}
                      />
                    )}
                  {step.step !== 3 &&
                    step.number_of_pages !== step.position && (
                      <LBTChip
                        label={`${Math.round((step.position * 100) / (step.number_of_pages ?? 1))}%`}
                        color="lemonCake"
                        sx={{ minWidth: '50px', marginRight: '10px' }}
                      />
                    )}
                  <IconArrowRightComponent />
                </Stack>
              }
              sx={{ minHeight: '58px' }}
            />
          ))}
        </Box>
        <LBTSpacer spacing={4} />
        {!isQuestionaryCompleted && (
          <LBTButton
            variant="contained"
            fullWidth
            onClick={() => navigate(`/${APP_ROUTES.QUESTIONARY}`)}
          >
            Continua a rispondere
          </LBTButton>
        )}
      </Section>
      {isQuestionaryCompleted && (
        <div
          style={{
            width: '100%',
            height: isDesktop ? '514px' : '360px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage:
              'radial-gradient(181.73% 86.53% at 51.79% 90.97%, #59A5FF 29%, rgba(255, 255, 255, 0.05) 100%)',
          }}
        >
          <LBTSpacer spacing={4} />
          <LBTLabel variant="smallCapsBold">Incredibile ma vero</LBTLabel>
          <LBTSpacer spacing={1} />
          <LBTLabel variant="spGroteskSubtitle">
            Hai risposto a tutte le domande
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <ImageAstronaut size={isDesktop ? 300 : 200} />
        </div>
      )}
    </div>
  );
}
