import Divider, { DividerProps } from '@mui/material/Divider';
import { LBTDividerSize } from '../../enums';
import { COLORS } from '../../utils';

export type LBTDividerProps = DividerProps & {
  size?: LBTDividerSize;
  color?: string;
  width?: string;
  datatestid?: string;
};

export default function LBTDivider({
  size = LBTDividerSize.SMALL,
  color = `${COLORS.getInstance().BW_GREYS_JET_BLACK}26`,
  width = '100%',
  orientation = 'horizontal',
  datatestid = '',
  ...props
}: LBTDividerProps) {
  return (
    <Divider
      sx={{
        datatestid: `lbt-divider-${datatestid}`,
        width: orientation === 'horizontal' ? '100%' : size,
        height: orientation === 'vertical' ? '100%' : size,
        backgroundColor: color,
        border: 0,
      }}
      {...props}
    />
  );
}
